import { useContext, useEffect, useState } from "react"
import { AuthContext } from "src/auth/AuthContext"
import { getListTable } from "src/Services/CrudServices"

export const useChartDonnutOrder = ({
    path,
    paramsInitial
}) => {

    const { user } = useContext(AuthContext)
    const [ loading, setLoading ] = useState(false)
    const [ listCharts, setListCharts ] = useState([])
    const [ listColors, setListColors ] = useState([])
    const [ listLabels, setListLabels ] = useState([])
    const [ idsStatus, setIdsStatus ] = useState({})

    const getOrders = async(paramsQuery) => {
        setLoading(true)
        const resOrders = await getListTable(user.token, path, paramsQuery)
        setLoading(false)
        const labelsGlobal = []
        const colorsGlobal = []
        let dictionaryIdsStatus = {}
        const chartsValues = resOrders.map((dataChart) => {
            const listLabel = []
            const listValues = []
            const colors = []
            const data = []
            dataChart.by_status.map((value) => {
                Object.keys(value).map((keyValue) => {
                    if(keyValue == "color") {
                        !colorsGlobal.includes(value[keyValue]) && colorsGlobal.push(value[keyValue])
                        colors.push(value[keyValue])
                        return
                    }
                    if(keyValue == "id") {
                        data.push(value[keyValue])
                        return
                    }
                    !labelsGlobal.includes(keyValue) && labelsGlobal.push(keyValue)
                    listLabel.push(keyValue)
                    dictionaryIdsStatus = {
                        ...dictionaryIdsStatus,
                        [keyValue]: value.id
                    }

                    listValues.push(value[keyValue])
                })
            })
            return {
                code: dataChart.code,
                idCategory: dataChart.id,
                idStatus: data,
                total: dataChart.total,
                labels: listLabel,
                values: listValues,
                colors: colors,
            }
        })
        setIdsStatus(dictionaryIdsStatus)
        setListCharts(chartsValues)
        setListColors(colorsGlobal)
        setListLabels(labelsGlobal)
    }   

    useEffect(() => {
        //getOrders(paramsInitial)
    }, [])

    return {
        listCharts,
        getOrders,
        loading,
        listColors,
        listLabels,
        idsStatus
    }
}