import moment from "moment"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { AuthContext } from "src/auth/AuthContext"
import { getListTable } from "src/Services/CrudServices"
import { rangeCurrentMonth } from "src/utils/DriverDate"
import qs from "qs"
import { getDashboardByPeriod } from "src/Services/DashboardServices"


export const MetricsContext = createContext()
const PARAMS_INITIAL = "start=2022-10-01T00:00:00Z&end=2022-10-31T23:59:59Z"

export const MetricsProvider = ({
    children
}) => {
    const { user } = useContext(AuthContext)
    const [status, setStatus] = useState(false)
    const [costsResults, setCostResults] = useState({
        avgtotal: 0,
        invoicesDaily: "0.00",
        monthlyInvoice: "0.00"
    })
    const [dailyGraph, setDailyGraph] = useState({
        metrics: [],
        total: 0
    })
    const [dailyLegsByGroup, setDailyLegsByGroup] = useState({
        metrics: [],
        total: 0,
        drivers: 0
    })
    const [dailyOrdersByCategory, setDailyOrdersByCategory] = useState({
        metrics: [],
        total: 0
    })
    const dataChart = useMemo(() => {

        let labels = []
        let background = []
        let data = []
        let maxValue = 0

        dailyGraph.metrics.map((value) => {
            if (value.total === 0) return

            labels.push(value.name)
            background.push(value.color)
            data.push(value.total)
            if (value.total > maxValue) {
                maxValue = value.total
            }
        })

        return {
            labels: labels,
            backgroundColor: background,
            data: data,
            maxValue: maxValue
        }
    }, [dailyGraph])
    const [delay, setDelay] = useState(30000)
    const [queryMetrics, setQueryMetrics] = useState("")
    const [typeFilter, setTypeFilter] = useState("months")
    
    const [ infoDashboardMix, setInfoDashboardMix ] = useState({
        metrics: [],
        total: 0
    })


    const getMetrics = async () => {

        const params = {
            start: `${moment().format("YYYY-MM-DD")}T00:00:00Z`,
            end: `${moment().format("YYYY-MM-DD")}T23:59:59Z`
        }
        const startOfWeek = moment().startOf('week').format("YYYY-MM-DD")
        const endOfWeek = moment().endOf('week').format("YYYY-MM-DD")
        try {
            const resData = await getDashboardByPeriod({
                token: user.token,
                params: {
                    period: "week",
                    start: startOfWeek,
                    end: endOfWeek
                }
            })

            if (resData) {
                setInfoDashboardMix(resData.data)
            }

            const resMetrics = await getListTable(user.token, "dashboard/globalDailyData", qs.stringify(params))

            if (!resMetrics) return

            setDailyGraph(resMetrics.data.orderByStatus)
            setDailyLegsByGroup(resMetrics.data.legByStatus)
            setDailyOrdersByCategory(resMetrics.data.orderByCategories)
        } catch (error) {

        }


    }

    useEffect(() => {
        if (!status) return
        getMetrics()

        const interval = setInterval(() => {
            getMetrics()
        }, delay)

        return () => {
            clearInterval(interval)
        }
    }, [status])

    return (
        <MetricsContext.Provider
            value={{
                costsResults,
                dailyGraph,
                dailyLegsByGroup,
                dailyOrdersByCategory,
                dataChart,
                infoDashboardMix,
                onStatus: (value) => setStatus(value),
                onDelay: (value) => setDelay(value),
                onQuery: (value) => setQueryMetrics(value),
                typeFilter,
                setTypeFilter
            }}
        >
            {children}
        </MetricsContext.Provider>
    )

}