export const SuccessIcon = (props) => {
    return (
        <svg
            width={125}
            height={125}
            viewBox="0 0 125 125"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M55.208 86.458L91.927 49.74l-7.292-7.292-29.427 29.427-14.844-14.844-7.291 7.292 22.135 22.135zm7.292 28.125c-7.205 0-13.976-1.368-20.313-4.104-6.337-2.733-11.849-6.443-16.536-11.13-4.688-4.688-8.398-10.2-11.13-16.537-2.736-6.336-4.105-13.107-4.105-20.312 0-7.205 1.369-13.976 4.105-20.313 2.732-6.336 6.442-11.848 11.13-16.536 4.687-4.688 10.2-8.4 16.536-11.135 6.337-2.733 13.108-4.1 20.313-4.1 7.205 0 13.975 1.367 20.312 4.1 6.337 2.736 11.85 6.447 16.537 11.135 4.687 4.688 8.397 10.2 11.13 16.537 2.736 6.336 4.104 13.107 4.104 20.312 0 7.205-1.368 13.976-4.104 20.313-2.733 6.336-6.443 11.848-11.13 16.536-4.688 4.687-10.2 8.397-16.537 11.13-6.337 2.736-13.107 4.104-20.312 4.104z"
                fill="#21E314"
            />
        </svg>
    )
}