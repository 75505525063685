import axios from 'axios'
import { API_TRUCKPORT } from 'src/Contanst/Contants'

export const forgotPassword = async (emailRecover) => {
  try {
    const { status, data } = await axios.post(`${API_TRUCKPORT}/auth/forgot-password`, {
      email: emailRecover,
    })
    return status == 200 ? true : false
  } catch (error) {
    const { response } = error
    throw response?.data.error
  }
}
export const resetPassword = async (data) => {
  try {
    const { status } = await axios.post(`${API_TRUCKPORT}/auth/reset-password`, data)
    return status == 200 ? true : false
  } catch (error) {
    const { response } = error
    throw response?.data.error
  }
}

export const verfiedEmailAndUser = async (token, identifier) => {
  try {
    const { status, data } = await axios.get(`${API_TRUCKPORT}/user/exists`, {
      params: {
        identifier: identifier,
      },
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    return status == 200 ? data : false
  } catch (error) {
    const { response } = error

    throw response?.data.error
  }
}
