import { useContext } from "react"
import { MODE, PreferencesContext } from "src/context/Preferences/PreferencesContext"

export const usePreferences = () => {
    
    const { darkMode, handleMode } = useContext(PreferencesContext)

    return {
        darkMode,
        handleMode,
        optionsMode: MODE
    }
}