import axios from "axios";
import { API_TRUCKPORT } from "src/Contanst/Contants";

export const getListTable = async (token, route, params = "") => {
    try {
        const { data, status } = await axios.get(`${API_TRUCKPORT}/${route}?${params}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })

        return status == 200 ? data : false
    } catch (error) {
        console.error(error);
    }
}

export const createItem = async (token, route, data) => {
    try {
        const { status, data: resData } = await axios.post(`${API_TRUCKPORT}/${route}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })

        return status == 200 || status == 201 ? resData : false
    } catch (error) {
        
        throw error.response.data.error.message
    }
}

export const updateItem = async (token, id, route, data) => {
    try {
        const { status } = await axios.put(`${API_TRUCKPORT}/${route}/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })

        return (status == 200 || status === 204) ? true : false
    } catch (error) {
        console.error(error);
    }
}

export const deleteItem = async (token, id, route) => {
    try {
        const { status } = await axios.delete(`${API_TRUCKPORT}/${route}/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })

        return status == 200 ? true : false
    } catch (error) {
        console.error(error);
    }
}

export const postCustom = async (token, route, data) => {
    try {
        const { status } = await axios.post(`${API_TRUCKPORT}/${route}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })

        return status == 200 ? true : false
    } catch (error) {
        console.error(error);
    }
}
export const uploadFile = async (token, path, formdata, onProgress = () => {}) => {
    try {
        const { status, data } = await axios.post(`${API_TRUCKPORT}/${path}`, formdata, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress(e) {
                onProgress(Math.round((e.loaded * 100) / e.total))
            }
        })
        return status == 200 ? data || true : false
    } catch (error) {
        console.error(error)
    }
}
export const uploadFilePut = async (token, path, formdata, onProgress = () => {}) => {
    try {
        const { status, data } = await axios.put(`${API_TRUCKPORT}/${path}`, formdata, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })

        return status === 204 ? data || true : false
    } catch (error) {

        throw error
    }
}

export const getOneItemServices = async({ token, route, params = {}, id }) => {
    try {
        const { data, status } = await axios.get(`${API_TRUCKPORT}/${route}/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            params: params
        })

        return status == 200 ? data : false
    } catch (error) {
        console.error(error);
    }
}