const { default: axios } = require('axios')
const { useState, useEffect } = require('react')
const url = `${process.env.REACT_APP_BASE_URL}/settings?populate[loginImage]=*&populate[sidebarImageDark][fields][0]=url`
export const useSystemLogos = () => {
  const [logos, setLogos] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    const fetchImage = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(url)
        setLogos(response.data.data)
      } catch (error) {
        console.error('Error al traer logos:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchImage()
  }, [url])

  return [logos, isLoading]
}
