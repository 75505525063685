export const ConfusedIcon = (props) => {
    return (
        <svg
            width={125}
            height={125}
            viewBox="0 0 125 125"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M62.5 10.417a52.084 52.084 0 100 104.167 52.084 52.084 0 000-104.167m-18.23 31.25a7.812 7.812 0 11-7.812 7.812 8.02 8.02 0 017.813-7.812m44.27 41.666H67.709A20.834 20.834 0 0049.635 93.75l-9.01-5.208a31.25 31.25 0 0127.083-15.625h20.834m-7.813-15.625a7.813 7.813 0 117.813-7.813 8.02 8.02 0 01-7.813 7.813z"
                fill="#fff"
            />
        </svg>
    )
}