import { useParams } from "react-router-dom"
import { ConfusedIcon } from "src/assets/Icons/ConfusedIcon"
import { ExpiredIcon } from "src/assets/Icons/ExpiredIcon"
import { SuccessIcon } from "src/assets/Icons/SuccessIcon"
import styles from "./ConfirmateLeg.module.css"

function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.href);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export const ConfirmateLeg = () => {

    const status = getParameterByName("status")

    

    return (
        <div className={styles.container} >
            <div className={styles.contentMessage} >
                {status == "used" &&
                    <>
                        <ConfusedIcon />
                        <p>
                            This leg has already been accepted
                        </p>
                    </>
                }
                {status == "expired" &&
                    <>
                        <ExpiredIcon />
                        <p>
                            This leg has already expired
                        </p>
                    </>
                }
                {status == "accepted" &&
                    <>
                        <SuccessIcon />
                        <p>
                            You have accepted this Leg
                        </p>
                    </>
                }
            </div>
        </div>
    )
}