export const EyeInvisibleIcon = (props) => {

    return (
        <svg
            width={14}
            height={15}
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_1531_40032)" fill="#ABABAB">
                <path d="M6.894 5.283l2.004 2.004.01-.105a1.91 1.91 0 00-1.91-1.909l-.104.01z" />
                <path d="M6.998 4a3.182 3.182 0 013.181 3.182c0 .41-.082.801-.226 1.16l1.861 1.862A7.556 7.556 0 0014 7.182C12.896 4.389 10.182 2.41 6.998 2.41a7.44 7.44 0 00-2.535.446l1.374 1.37c.36-.14.75-.225 1.161-.225zM.636 2.267l1.45 1.45.29.29A7.536 7.536 0 000 7.182a7.524 7.524 0 006.998 4.771c.987 0 1.928-.19 2.79-.537l.27.27 1.855 1.858.811-.808L1.447 1.456l-.81.811zm3.519 3.515l.982.983a1.91 1.91 0 001.86 2.325c.145 0 .281-.019.415-.047l.983.983c-.423.21-.894.337-1.397.337a3.182 3.182 0 01-3.18-3.181c0-.503.127-.974.337-1.4z" />
            </g>
            <defs>
                <clipPath id="clip0_1531_40032">
                    <path fill="#fff" transform="translate(0 .5)" d="M0 0H14V14H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}