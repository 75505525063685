import axios from 'axios'
import { API_TRUCKPORT } from 'src/Contanst/Contants'

export const getDashboardCards = async ({ token, date, paramsExtra = '' }) => {
  try {
    const resGet = await axios.get(
      `${API_TRUCKPORT}/dashboard/order-filters?date=${date}${paramsExtra}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    )

    return resGet.status === 200 ? resGet.data : false
  } catch (error) {
    throw 'No se completo la solicitud'
  }
}

export const getDashboardCardsBilling = async ({ token, date }) => {
  try {
    const resGet = await axios.get(`${API_TRUCKPORT}/dashboard/billing/cards`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })

    return resGet.status === 200 ? resGet.data : false
  } catch (error) {
    throw 'No se completo la solicitud'
  }
}

export const getDashboardByPeriod = async ({ token, params }) => {
  try {
    const resGet = await axios.get(`${API_TRUCKPORT}/dashboard/orders/by/period`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      params: params,
    })

    return resGet.status === 200 ? resGet.data : false
  } catch (error) {
    throw 'No se completo la solicitud'
  }
}

export const getDashboardByDay = async ({ token, params }) => {
  try {
    const resGet = await axios.get(`${API_TRUCKPORT}/dashboard/fees`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      params: params,
    })

    return resGet.status === 200 ? resGet.data : false
  } catch (error) {
    throw 'No se completo la solicitud'
  }
}

export const getDashboardSingleMetrics = async ({ token, params }) => {
  try {
    const resGet = await axios.get(`${API_TRUCKPORT}/dashboard/single/metrics`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      params: params,
    })

    return resGet.status === 200 ? resGet.data : false
  } catch (error) {
    throw 'No se completo la solicitud'
  }
}
