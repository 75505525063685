export const LetterIcon = ({
    color = "#000842"
}) => {

    return (
        <svg
            width={18}
            height={12}
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.994.177h14.012c.824 0 1.494.67 1.494 1.494v8.658c0 .824-.67 1.495-1.494 1.495H1.994c-.824 0-1.494-.67-1.494-1.495V1.671C.5.847 1.17.177 1.994.177zm.195.996l.2.166L8.407 6.35a.926.926 0 001.186 0L15.61 1.34l.2-.166H2.189zm14.315.719L11.6 5.975l4.904 3.264V1.892zm-14.51 8.936h14.012c.24 0 .442-.172.488-.4L10.8 6.64l-.57.476a1.919 1.919 0 01-2.461 0l-.571-.476-5.693 3.789a.499.499 0 00.488.399zm-.498-1.59L6.4 5.976 1.496 1.892v7.347z"
                fill={color}
            />
        </svg>
    )
}