import { createContext, useState } from 'react'
import { useChartDonnutOrder } from 'src/hooks/useChartDonnutOrder'

export const DashboardContext = createContext()

export const DashboardProvider = ({ children }) => {
  const [dateFilter, setDateFilter] = useState({
    start: '',
    end: '',
  })
  const [typeFilter, setTypeFilter] = useState('days') // "days, months, years, weeks, custom"
  const { getOrders, listCharts, loading, listColors, listLabels, idsStatus } = useChartDonnutOrder(
    {
      path: 'dashboard/orders',
    },
  )

  const [rangeDate, setRangeDate] = useState({
    start: '',
    end: '',
  })
  const [typeRange, setTypeRange] = useState('')

  const onChangeFilters = (type, range) => {
    setRangeDate(range)
    setTypeRange(type)
  }

  // para manejar el valor de status de una orden cuando cambia el status de la misma automáticamente
  const [globalOrderStatus, setGlobalOrderStatus] = useState(false)

  // para manejar el valor del status de las legs globalmente en tiempo real
  const [globalCurrentOrderLegs, setGlobalCurrentOrderLegs] = useState([])

  // para manejar el los datos de la orden de tipo "DP" seleccionada de forma global y en tiempo real
  const [globalCurrentOrderDpData, setGlobalCurrentOrderDpData] = useState([])

  return (
    <DashboardContext.Provider
      value={{
        getOrders,
        listCharts,
        loading,
        listColors,
        listLabels,
        idsStatus,
        dateFilter,
        setDateFilter,
        typeFilter,
        setTypeFilter,
        onChangeFilters,
        rangeDate,
        typeRange,
        globalOrderStatus,
        setGlobalOrderStatus,
        globalCurrentOrderLegs,
        setGlobalCurrentOrderLegs,
        globalCurrentOrderDpData,
        setGlobalCurrentOrderDpData,
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}
