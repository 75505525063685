export const PaylockIcon = ({
    color = "#000842"
}) => {

    return (
        <svg
            width={17}
            height={18}
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1531_40025)" fill={color}>
                <path d="M13.003 7.73V5.037a4.514 4.514 0 00-1.348-3.243A4.37 4.37 0 008.552.5h-.058C6.011.504 3.993 2.536 3.993 5.037V7.73A1.838 1.838 0 002.37 9.578v6.048c0 1.027.822 1.874 1.849 1.874h8.56c1.027 0 1.848-.847 1.848-1.874V9.578a1.847 1.847 0 00-1.625-1.848zM4.71 5.037h.004c0-2.104 1.697-3.827 3.784-3.827H8.5a3.733 3.733 0 012.646 1.088 3.817 3.817 0 011.131 2.74v2.695h-.792V5.037a3.037 3.037 0 00-.898-2.18 2.922 2.922 0 00-2.065-.858h-.022C6.844 2 5.503 3.362 5.503 5.034v2.7H4.71V5.036zm6.058 0v2.696h-4.54V5.037c0-1.275 1.016-2.313 2.277-2.313h.022a2.21 2.21 0 011.56.648c.44.44.689 1.042.681 1.665zm3.172 10.6c0 .63-.512 1.142-1.143 1.142H4.234c-.63 0-1.142-.512-1.142-1.142v-6.04c0-.631.512-1.143 1.142-1.143h8.563c.631 0 1.143.512 1.143 1.142v6.04z" />
                <path d="M9.745 12.393A1.3 1.3 0 108.138 14v1.01c0 .198.162.36.36.36.199 0 .36-.162.36-.36V14a1.296 1.296 0 00.887-1.607zm-1.247.937a.58.58 0 110-1.16.58.58 0 010 1.16z" />
            </g>
            <defs>
                <clipPath id="clip0_1531_40025">
                    <path fill="#fff" transform="translate(0 .5)" d="M0 0H17V17H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}