export function validateEmail(valor) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3,4})+$/.test(valor)) {
    return false
  } else {
    return true
  }
}

export function converNumToLetter(num){

  switch(num)
  {
      case 1: return "One";
      case 2: return "Two";
      case 3: return "Tree";
      case 4: return "Four";
      case 5: return "Five";
      case 6: return "Six";
      case 7: return "Seven";
      case 8: return "Eight";
      case 9: return "Nine";
      case 10: return "Ten";
      case 11: return "Eleven";
  }

  return num;
}//Unidades()

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const ValidateToken = (token) => {
  const { exp } = parseJwt(token)

  if(!exp) return null
  const currentTimestamp = Math.floor(Date.now() / 1000)

  if (exp < currentTimestamp) {
    // El token ha expirado
    
    return false
  } else {
    // El token está vigente
    
    return true
  }

}