import moment from "moment"

export const rangeCurrentMonth = () => {

    const yearAndMonth = moment().format("YYYY-MM-DD")
    const initialMonth = `${yearAndMonth}-01`
    const daysMonth = moment(yearAndMonth).daysInMonth()

    const finishMonth = `${yearAndMonth}-${daysMonth}`

    return {
        start: yearAndMonth,
        end: yearAndMonth
    }
}

export const getFirtsDayWeek = () => {
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay(); // Obtener el número del día de la semana (0-6)
    const firstDayOfWeek = new Date(currentDate); // Clonar la fecha actual

    // Restar los días necesarios para obtener el primer día de la semana
    firstDayOfWeek.setDate(currentDate.getDate() - currentDayOfWeek);

    return firstDayOfWeek
}

export const getWeeksInMonth = (start, end) => {
    const weeks = [];
    const firstDayOfMonth = moment(start).startOf('month');
    const lastDayOfMonth = moment(end).endOf('month');
    let currentDay = firstDayOfMonth.clone();

    while (currentDay.isSameOrBefore(lastDayOfMonth)) {
        const weekStart = currentDay.clone().startOf('day');
        const weekEnd = currentDay.clone().add(6, 'days').endOf('day');

        if (weekEnd.isAfter(lastDayOfMonth)) {
            // Ajustar el final de la semana al último día del mes
            currentDay = lastDayOfMonth.clone();
            const weekRange = `${weekStart.format('MM-DD')} - ${currentDay.format('MM-DD')}`;
            weeks.push(weekRange);
            break;
        }

        const weekRange = `${weekStart.format('MM-DD')} - ${weekEnd.format('MM-DD')}`;
        weeks.push(weekRange);
        currentDay.add(7, 'days');
    }

    return weeks;
}