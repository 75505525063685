import { useEffect, useState } from "react"
import { createContext } from "react"

export const PreferencesContext = createContext()
export const MODE = {
    DARK: "dark",
    LIGTH: "ligth"
}

const KEY_PREFERENCES = "preferences"

export const PreferencesProvider = ({ children }) => {

    const [darkMode, setDarkMode] = useState(MODE.LIGTH) // ligth or dark

    const handleMode = () => {
        if (darkMode === MODE.DARK) {
            setDarkMode(MODE.LIGTH)
            document.body.setAttribute("class", "")
            localStorage.setItem(KEY_PREFERENCES, JSON.stringify({ mode: MODE.LIGTH }))

            return
        }
        setDarkMode(MODE.DARK)
        localStorage.setItem(KEY_PREFERENCES, JSON.stringify({ mode: MODE.DARK }))
        document.body.setAttribute("class", "darkmode")
    }

    useEffect(() => {
        const prefences = localStorage.getItem(KEY_PREFERENCES)
        if(!prefences) return

        const prefencesUser = JSON.parse(prefences)
        setDarkMode(prefencesUser.mode)
        if(prefencesUser.mode === MODE.DARK) {
            document.body.setAttribute("class", "darkmode")
        }
    }, [])

    return (
        <PreferencesContext.Provider
            value={{
                darkMode,
                handleMode
            }}
        >
            {children}
        </PreferencesContext.Provider>
    )
}