import { useState } from "react"
import { useEffect } from "react"
import { createContext } from "react"

export const SearchContext = createContext()

export const SearchProvider = ({ children }) => {
    const [search, setSearch] = useState('') // ligth or dark
    const [ pathAction, setPatchAction ] = useState("")

    // useEffect(() => {
    //  setSearch(location.pathname != '/orders' ? '' : search)
    // }, [location.pathname])
    
    const handleSearch = (value) => {
        setSearch(value)
    }

    const searchManteiner = (value, path) => {
        setSearch(value)
        setPatchAction(path)
    }

    return (
        <SearchContext.Provider
            value={{
                search,
                handleSearch,
                searchManteiner,
                pathAction
            }}
        >
            {children}
        </SearchContext.Provider>
    )
}